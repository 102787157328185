:root,
.out-team {
  --background: #f5f6fa;
  --page-title: #3c9045;
  --btn: #dcdde1;
  --btn-text: #b33771;
  --btn-text-hover: #dcdde1;
  --btn-bkg: #b33771;
  --btn-fill: transparent;
  --nav-bkg: rgba(245, 246, 250, 0.75);
  --nav-logo: #b33771;
  --nav-border: rgba(245, 246, 250, 0.2);
  --header-bkg: #3c9045;
  --bio-text: #fff;
  --bio-bkg: #182c61;
  --bio-bkg-hover: #b33771;
  --name-text: #2c3a47;
  --name-text-hover: #3b3b98;
}

.out-team section {
  text-align: center;
}

.out-team button {
  border: 0;
  background: none;
  fill: var(--btn-fill);
  color: var(--btn-text);
  line-height: 0;
  padding: 0.8rem;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
}
.out-team button:hover {
  cursor: pointer;
  background: var(--btn-bkg);
  fill: var(--btn-fill);
  color: var(--btn-text-hover);
}

.out-team main {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  position: absolute;
  top: 130px;
}

.out-team .page-title {
  color: var(--page-title);
  font-size: 38px;
  margin-bottom: 3rem;
  margin-top: 1rem;
}

.out-team .leadership {
  display: flex;
  justify-content: center;
  gap: 5rem;
}
@media screen and (max-width: 768px) {
  
}

@media screen and (max-width: 640px) {
  .out-team .leadership {
    flex-direction: column;
}
}

.out-team .leadership .leader {
  width: 100%;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
  margin: auto;
  max-width: 300px;
}

.out-team .leadership .leader:hover {
  cursor: pointer;
}
.out-team .leadership .leader:hover .leader__bio {
  background-color: var(--bio-bkg-hover);
}
.out-team .leadership .leader:hover .leader__img img {
  transform: scale(140%);
  filter: saturate(100%);
}
.out-team .leadership .leader:hover .leader__name {
  color: var(--name-text-hover);
}
.out-team .leadership .leader__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: var(--header-bkg);
  color: #fff;
}
.out-team .leadership .leader__exp {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding: 1rem 2rem;
}
.out-team .leadership .leader__exp .years {
  font-size: 5rem;
  margin-right: 8px;
  font-weight: 700;
}
.out-team .leadership .leader__bio {
  padding: 1.5rem 2rem;
  font-size: 2.5rem;
  text-decoration: none;
  color: var(--bio-text);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: var(--bio-bkg);
  transition: 0.2s all ease-in-out;
}
.out-team .leadership .leader__img {
  overflow: hidden;
  width: 100%;
  height: 300px;
  position: relative;
}
.out-team .leadership .leader__img img {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 100%;
  filter: saturate(0%);
  transform: scale(130%);
  transition: 0.2s all ease-in-out;
}
.out-team .leadership .leader__name {
  text-align: center;
  padding: 1.5rem;
  font-size: 2rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s all ease-in-out;
  color: var(--name-text);
}
.out-team .leadership .leader__name p {
  font-size: 1.5rem;
}
@media (min-width: 32em) {
  .out-team .leadership .leader__name {
    font-size: 1.5rem;
  }
}
@media (min-width: 50em) {
  .out-team .leadership .leader__name {
    font-size: 2rem;
  }
}
.out-team .leadership .leader__name h2 {
  font-size: 2rem;
}
@media (min-width: 32em) {
  .out-team .leadership .leader__name h2 {
    font-size: 2rem;
  }
}
@media (min-width: 50em) {
  .out-team .leadership .leader__name h2 {
    font-size: 2rem;
  }
}



.preview-card {
  position: relative;
  margin: 15px;
  background: #fff;
  box-shadow: 0px 3px 10px rgba(34, 35, 58, 0.2);
  padding: 30px 25px 30px;
  border-radius: 25px;
  transition: all 0.3s;
}
@media screen and (max-width: 992px) {
  .preview-card {
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .preview-card {
    min-height: 500px;
    height: auto;
    margin: 120px auto;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .preview-card {
    height: auto;
  }
}
.preview-card__item {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .preview-card__item {
    flex-direction: column;
  }
}
.preview-card__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > * {
  opacity: 1;
  transform: none;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
  transition-delay: 0.4s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
  transition-delay: 0.5s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
  transition-delay: 0.6s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
  transition-delay: 0.7s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
  transition-delay: 0.8s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
  transition-delay: 0.9s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
  transition-delay: 1s;
}
.preview-card__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
  transition-delay: 1.1s;
}
.preview-card__item.swiper-slide-active
  .blog-slider__content
  > *:nth-child(10) {
  transition-delay: 1.2s;
}
.preview-card__item.swiper-slide-active
  .blog-slider__content
  > *:nth-child(11) {
  transition-delay: 1.3s;
}
.preview-card__item.swiper-slide-active
  .blog-slider__content
  > *:nth-child(12) {
  transition-delay: 1.4s;
}
.preview-card__item.swiper-slide-active
  .blog-slider__content
  > *:nth-child(13) {
  transition-delay: 1.5s;
}
.preview-card__item.swiper-slide-active
  .blog-slider__content
  > *:nth-child(14) {
  transition-delay: 1.6s;
}
.preview-card__item.swiper-slide-active
  .blog-slider__content
  > *:nth-child(15) {
  transition-delay: 1.7s;
}
.preview-card__img {
  width: 300px;
  flex-shrink: 0;
  height: 300px;
  background-image: linear-gradient(147deg, #000 0%, #000 74%);
  box-shadow: 0px 3px 10px 1px rgba(252, 56, 56, 0.2);
  border-radius: 20px;
  transform: translateX(-80px);
  overflow: hidden;
}
.preview-card__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(147deg, #000 0%, #000 74%);
  border-radius: 20px;
  opacity: 0.4;
}
.preview-card__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  opacity: 1;
  border-radius: 20px;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .preview-card__img {
    transform: translateY(-50%);
    width: 90%;
  }
}
@media screen and (max-width: 576px) {
  .preview-card__img {
    width: 95%;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .preview-card__img {
    height: 270px;
  }
}
.preview-card__content {
  padding-right: 25px;
}
@media screen and (max-width: 768px) {
  .preview-card__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .preview-card__content {
    padding: 0;
  }
}
.preview-card__content > * {
  transform: translateY(25px);
  transition: all 0.4s;
}
.preview-card__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.preview-card__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.preview-card__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.preview-card__button {
  display: inline-flex;
  background-image: linear-gradient(147deg, #000 0%, #000 74%);
  padding: 15px 35px;
  margin-bottom: 30px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 3px 10px rgba(252, 56, 56, 0.4);
  text-decoration: none;
  font-weight: 500;
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
}
.preview-card__button:hover {
  color: #989898;
  text-decoration: none;
}
@media screen and (max-width: 576px) {
  .preview-card__button {
    width: 100%;
  }
}
